<template>
  <div class="DrawerContributors">

    <div class="DrawerContributors--container">

      <div
      class="DrawerContributors--container--list"
      :class="['DrawerContributors' + context, 'Drawerlist-' + index]"
      v-for="(element, index) in content"
      :key="index">

        <ul class="DrawerContributors--container--list--title">
          <!-- <p>{{element.title}}</p> -->

          <template v-if="element.title == 'Texte'">
            <template v-if="$route.params.language == 'fr'"><p>Textes</p></template>
            <template v-if="$route.params.language == 'en'"><p>Texts</p></template>
          </template>

          <template v-else><p>Images</p></template>

        <div class="DrawerContributors--container--list--container" :class="['DrawerContributors' + context, 'Drawerlist-' + index]">
          <li class="DrawerContributors--container--list--element" v-for="(name, indexx) in element.content" :key="indexx">

            <template v-if="name.hasClick"><span class="hasClick" @click="setSearch(name.value)">{{name.value}}</span></template>

            <template v-else><span>{{name.value}}</span></template>

          </li>

        </div>

        </ul>

      </div>

    </div>

  </div>
</template>

<script>
export default {
  name: 'DrawerContributors',
  props: ['content', 'context'],
  computed:{

  },
  methods: {
    setSearch: function(value){
      this.$router.push({name:'recherche', params:{language: this.$route.params.language}, hash: '#' + value});
    }
  }
}
</script>

<style lang="scss">

.DrawerContributors{
  font-size: var(--univers-font-labeur-rgl);
  
  width: 100%;

  // padding: var(--esp-big);
  
  &--container{

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    width: calc(100% - ( 2 * var(--esp-big)));
    padding: var(--esp-big);

    &--list{
      // width: 50%;

      // width: calc( 100% / 3 - var(--esp-big));

      width: calc( 100% / 3 - ( 1 * var(--esp-med)));

      &.Drawerlist-texte_compared{
        width: calc( (100% / 3 * 1) - ( 1 * var(--esp-med)));
      }

      &.Drawerlist-image_compared{
        width: calc( (100% / 3 * 2) - ( 1 * var(--esp-med)));
        // columns: 2;
        // column-fill: auto;
      }

      &--title{
        font-size: var(--univers-font-labeur-rgl); 
        font-family: 'vd-reg';
        // text-align: center;
        text-align: left;

        & p {
          margin-bottom: var(--esp-big);
        }
      }

      &--element{
        width: 100%;

        font-family: 'oli';
        font-size: var(--univers-font-labeur-rgl);
        text-decoration: none;
        text-align: left;

        & .hasClick{
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }
}


.DrawerContributors--container--list--container.Drawerlist-image_compared{
  columns: 2;
}

.DrawerContributors--container--list--container.DrawerContributors-portrait{
  columns: 1;
}

.DrawerContributors--container--list--container.DrawerContributors-mobile{
  columns: 1;
}

.DrawerContributors--container--list.DrawerContributors-portrait{
  width: calc( 100% / 2 - ( 1 * var(--esp-med)));
}

.DrawerContributors--container--list.DrawerContributors-mobile{
  width: calc( 100% / 2 - ( 1 * var(--esp-med)));
}


</style>
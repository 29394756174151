<template>
  <div class="UniversTeam">


    <div class="UniversTeam--intro" v-html="content.intro"></div>

    <div
    class="UniversTeam--drawer"
    v-for="(section, index) in content.structure"
    :style="{'background-color' : whatColor(index)}"
    :key="index">

      <div
      class="UniversTeam--drawer--title"
      v-html="section.title"
      @click="toggleSlider(index)"
      >
      </div>

      <transition name="toggleDrawer" mode="out-in">

      <!-- <keep-alive> -->
      <component 
      :is="whatComponent(section.component)" 
      v-if="sliderIsOpen[index]"
      :content="section.content"
      :context="whatComputConfig"
      ></component>
      <!-- </keep-alive> -->
      
      </transition>

    </div>


    <div class="UniversTeam--outro" 
    :style="{'background-color' : whatColor(content.structure.length)}"
    v-html="content.outro"></div>

  </div>
</template>

<script>
import DrawerTeam from '@/components/univers/universteam/DrawerTeam.vue'
import DrawerContributors from '@/components/univers/universteam/DrawerContributors.vue'
import DrawerDiffusion from '@/components/univers/universteam/DrawerDiffusion.vue'
import DrawerStore from '@/components/univers/universteam/DrawerStore.vue'

import DrawerMentions_legales from '@/components/univers/universteam/DrawerMentions_legales.vue'
import DrawerCgv from '@/components/univers/universteam/DrawerCgv.vue'

import clientConfigs from '@/mixins/clientConfigs.js'

export default {
  name: 'UniversTeam',
  mixins: [clientConfigs],
  props: ['content'],
  components: {
    DrawerTeam,
    DrawerContributors,
    DrawerDiffusion,
    DrawerStore,

    DrawerMentions_legales,
    DrawerCgv
  },
  data: function(){
    return {
      tabIsVisible: [],
    }
  },

  computed:{



    sliderIsOpen: function() {
        return this.tabIsVisible.map(function(item) {
            return item;
        });
    }

  },
  methods: {

    whatComponent: function(name){

      let newName = name.charAt(0).toUpperCase() + name.slice(1);
      return 'Drawer' + newName
      
    },

    whatColor: function(index){
      if (index%2 == 0){
        return 'var(--third-color-lighten)'
      }else{
        return 'var(--third-color)'
      }
    },

    initTabIsVisible: function(){
      this.content.structure.forEach((element, index) => this.$set(this.tabIsVisible, index, false) )
    },

    // toggleSlider: function(index){
    //   this.$set(this.tabIsVisible, index, !this.tabIsVisible[index])
    // }

    toggleSlider: function(index){

      let elementTitle = this.$el.querySelectorAll('.UniversTeam--drawer')[index].querySelector('.UniversTeam--drawer--title')
      let elementBottomPosition = elementTitle.getBoundingClientRect().bottom
      let RemoveToScrollTop = window.innerHeight - elementBottomPosition

      if ( this.tabIsVisible[index] == true ){

        this.$el.parentNode.scrollTo({
          top: this.$el.parentNode.scrollTop - RemoveToScrollTop,
          left: 0,
          behavior: 'smooth'
        });
      }


      
      this.$set(this.tabIsVisible, index, !this.tabIsVisible[index])
    }


  },
  created(){
    this.initTabIsVisible()

  }
  
}
</script>

<style lang="scss">

.toggleDrawer-enter-active, .toggleDrawer-leave-active {
  transition: all .6s;
  max-height: 1000px;
}

.toggleDrawer-leave-active{
  transition: all .3s;
}

.toggleDrawer-enter, .toggleDrawer-leave-to /* .fade-leave-active below version 2.1.8 */ {
  max-height: 0px;
  opacity: 0;
}



.UniversTeam{
  position: relative;
  width: 100%;
  overflow: hidden;

  &--intro{
    font-size: var(--univers-font-labeur-rgl);
    text-align: center;
    padding: var(--esp-big);
  }

  &--outro{
    font-size: var(--univers-font-labeur-rgl);
    text-align: center;
    padding: var(--esp-big);
  }

  &--drawer{
    width: 100%;

    &--title{
      font-size: var(--header-title-font);
      text-align: center;
      text-decoration: underline;

      cursor: pointer;
      // margin: var(--esp-big);
      padding: var(--esp-big);
      
      & p {

      }
    }

    &--content{
      font-size: var(--univers-font-labeur-rgl);
      text-align: center;

      & p {
        padding: var(--esp-big);
      }
    }
  }
}





</style>
<template>
  <div class="HeaderFiltersMobile">
    <div 
    v-for="filter in filtersTab" 
    :key="filter.name" 
    @click="emitFilters(filter.value)"
    class="HeaderFiltersMobile--element"
    :class="filter.value === activeFilter ? 'active ' + $route.name : ''" 
    > 
    {{ filter.name }} mobile
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeaderFiltersMobile',
  props: ['filtersTab', 'activeFilter'],
  methods: {
	emitFilters(filter) {
		this.$emit('set-filter', { filter })
	},

  }
}
</script>

<style lang="scss">

.HeaderFiltersMobile{
	font-size: 3vw;
	display: flex;
	flex-wrap: wrap;

	&--element{
		width: 50%;
		text-align: center;

		&.active{}
	}
}

.HeaderFiltersMobile--element.active.art{
  color: var(--first-color);
  background-color: black;
}

.HeaderFiltersMobile--element.active.amateur{
  color: var(--second-color);
  background-color: black;
}

</style>

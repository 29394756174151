<template>
  <div class="DrawerMentions_legales">
    <div class="DrawerMentions_legales--content" v-html="content.content"></div>
  </div>


</template>

<script>
export default {
  name: 'DrawerMentions_legales',
  props: ['content', 'context'],
  computed:{

  },
  methods: {

  },
  created(){
    console.log(this.content)
  }
  
}
</script>

<style lang="scss">



.DrawerMentions_legales{
  // width: 100%;
  // font-size: calc(var(--univers-font-labeur-rgl) / 5 * 3);
  font-size: var(--univers-font-labeur-rgl);
  padding: var(--esp-big);
  text-align: center;

  &--content{

    & p {
      padding-bottom: var(--esp-small);
    }
  }
}


</style>
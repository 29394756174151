<template>
  <!-- <div class="UniversRevue" v-html="content.text"> -->

    <div class="UniversRevue" v-htmltest>
      <!-- {{content.text}} -->



  </div>
</template>

<script>
export default {
  name: 'UniversRevue',
  props: ['content'],
  directives:{
    htmltest: {

      bind: function(el, binding, vnode){
        el.innerHTML = vnode.context.content.text

        let DOM_figures = Array.prototype.slice.call(el.querySelectorAll('figure'))
        let images_collection = vnode.context.content.media_collection

        let varImgWidth = Number(window.getComputedStyle(document.documentElement).getPropertyValue('--univers-thumb-workshop-width').split('vw')[0])

        images_collection.forEach((element, index) => {

          let img = new Image();
          img.sizes = "25vw"

          img.onload = function() {
            let natureWidth = img.width
            let natureHeight = img.height

            /*en pixel*/
            // let imgWidth = varImgWidth * window.innerWidth / 100
            // let imgHeight = imgWidth * natureHeight / natureWidth
            // img.style.width = imgWidth + 'px'
            // img.style.height = imgHeight + 'px'

            /*en vw*/
            let imgWidth = varImgWidth
            let imgHeight = imgWidth * natureHeight / natureWidth
            img.style.width = imgWidth + 'vw'
            img.style.height = imgHeight + 'vw'
            

            
            // img.srcset = element.srcset
            if(!(img.dataset.state == 'inserted')){
              DOM_figures[index].appendChild(img)
              img.dataset.state = 'inserted'
            }
          }
          // img.src = element.thumb
          img.srcset = element.srcset

          // DOM_figures[index].appendChild(img)
        })
      }
    }
  },
  computed:{

  },
  methods: {

  },
  created(){
    // console.log(this.content.media_collection)
  }
  
}
</script>

<style lang="scss">

.UniversRevue{

  font-size: var(--univers-font-labeur-rgl);
  margin: var(--esp-big);

  & h1{
    text-align: center;
    margin-bottom: var(--esp-big);
    text-decoration: underline;
  }

  & p {
    margin-bottom: var(--esp-big);
    text-align: center;
  }

  & figure{
    display: flex;
    justify-content: center;
    margin-bottom: var(--esp-big);
    // width: '';
    // height: '';
  }

  & img{
    // width: var(--univers-thumb-workshop-width);
    // height: '';
  }

}





</style>
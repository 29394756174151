<template>

  <transition name="fadeView" appear mode="out-in">
   
	<div class="Decouvertes" :class="whatConfig">

    <div class="Decouvertes--container" ref="container">

    <!-- <keep-alive> -->
    <Header 
    :title="page.title" 
    :HeaderFilters="HeaderFilters"
    :filtersTab="page.filters" 
    :activeFilter="activeFilter" 
    :context="'decouvertes'"
    @set-filter="setFilter"
    ></Header>
    <!-- </keep-alive> -->

    <!-- <keep-alive> -->
    <component :is="whatComponent" :content="whatContent"></component>
    <!-- </keep-alive> -->

    </div>

    <Scrollbar v-if="this.whatComputConfig != '-mobile'" :parent="this.$route.name"></Scrollbar>

	</div>
  </transition>

</template>

<script>
import Header from '@/components/Header.vue'

import UniversEvent from '@/components/univers/UniversEvent.vue'
import UniversWorkshop from '@/components/univers/UniversWorkshop.vue'
import UniversTeam from '@/components/univers/UniversTeam.vue'
import UniversRevue from '@/components/univers/UniversRevue.vue'

import Scrollbar from '@/components/scrollbar/Scrollbar.vue'
import clientConfigs from '@/mixins/clientConfigs.js'

export default {
  name: 'About',
  mixins: [clientConfigs],
  data: function () {
   return {
     state : this.$store.state,
     stateClient: this.$client.state,
     loadedData: false,
     activeFilter: 'event',

     desactivatedScrollValue: '',
     desactivated: false
   }
  },
  components: {
    Header,
    UniversEvent,
    UniversWorkshop,
    UniversTeam,
    UniversRevue,

    Scrollbar
  },
  watch: {
    activeFilter: function(){
      this.$el.scrollTop = 0
    }
  },
  computed: {

    page: function () { return this.$store.state.[this.$route.params.language].[this.$route.name] },  // est REACTIVE si json1 existe
    ClientWidth: function(){ return this.$client.state.scale },
    ClientFormat: function(){ return this.$client.state.format},
    ClientDevice: function(){ return this.$client.state.device},

    whatConfig: function(){
      if ( this.ClientDevice != 'desktop' || this.ClientFormat == 'portrait'){
        return 'decouvertes-mobile'
      }else {
        return ''
      }
    },

    HeaderFilters: function(){
      return this.ClientDevice === 'desktop' ? 'HeaderFilters' : 'HeaderFilters'
    },

    whatComponent: function(){
      let componentActive 
      if (this.activeFilter == 'event'){
        componentActive = 'UniversEvent'
      }
      if (this.activeFilter == 'workshop'){
        componentActive = 'UniversWorkshop'
      }
      if (this.activeFilter == 'team'){
        componentActive = 'UniversTeam'
      }
      if (this.activeFilter == 'revue'){
        componentActive = 'UniversRevue'
      }
      return componentActive
    },

    whatContent: function(){
      return this.page.[this.activeFilter]
    }, 
  },
  methods: {

    setFilter(payload) {
      this.activeFilter = payload.filter
    },   

    setSCrollValue: function(){
      this.desactivatedScrollValue = window.scrollY
      // console.log(this.desactivatedScrollValue) 
    } 
  },
  created() {
    window.scroll(0, 0);

    this.desactivated = false
    window.addEventListener('scroll', this.setSCrollValue )
  },
  activated(){
    // console.log('ACTIVATED')

    if (this.desactivated){
      window.scroll(0, this.desactivatedScrollValue);
      
    }else {
      window.scroll(0, 0);
    }

    window.addEventListener('scroll', this.setSCrollValue )
    this.desactivated = false
  },
  deactivated(){
    if (this.activeFilter == 'team'){
      this.desactivated = true
    }
    window.removeEventListener('scroll', this.setSCrollValue )
  },
  mounted(){
    this.$client.isInit = true
  },
  beforeDestroy(){
    window.removeEventListener('scroll', this.setSCrollValue )
  }
}

</script>

<style lang="scss">
@import '@/css/variables';
@import '@/css/transition';

.Decouvertes{

  z-index: 2;
  
  // position: fixed;
  // height: 100vh;
  // overflow: scroll;

  position: absolute;
  min-height: 100vh;
  // overflow: auto;

  width: var(--page-width);
  left: calc(var(--marge-page) / 2);

  background-color: var(--third-color);

  &--container{
    position: relative;
    height: auto;
    width: 100%;
  }
}

// .Decouvertes--container{
//   overflow: auto;
// }


// .Decouvertes.mobile{
//   z-index: 2;
//   position: fixed;
//   width: 100vw;  
//   height: calc(100vh - var(--menu-header-height));  
//   top: var(--menu-header-height);
//   left: 0;
//   overflow: scroll;
// }

.Decouvertes.decouvertes-mobile{
  background-color: var(--third-color);
  z-index: 2;
  position: absolute;
  min-height: calc(100% - var(--menu-header-height));

  width: 100vw; 
  left: 0;
  top: var(--menu-header-height);  
}




</style>
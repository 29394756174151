<template>
	<div class="Header">

		<div class="Header--margin" 
		:class="[titleisHiding ? 'isHiding':'', 'header' + whatComputConfig, 'header' + '-' + context]"
		></div>

		<div class="Header--content" 
		:class="[titleisHiding ? 'isHiding':'', 'header' + whatComputConfig, 'header' + '-' + context]">
			<HeaderTitle :title="title"></HeaderTitle>
			<component :is="HeaderFilters" :filtersTab="filtersTab" :activeFilter="activeFilter" @set-filter="emitFilters"></component>
		</div>	

	</div>
</template>

<script>
import HeaderTitle from '@/components/HeaderTitle.vue'
import HeaderFilters from '@/components/HeaderFilters.vue'
import HeaderFiltersMobile from '@/components/HeaderFiltersMobile.vue'

import CSSvariables from '@/css/variables.scss'
import clientConfigs from '@/mixins/clientConfigs.js'


export default {
  name: 'Header',
  mixins: [clientConfigs],
  props: ['title', 'filtersTab', 'activeFilter', 'HeaderFilters', 'whatPage', 'context'],
	data: function(){
		return {
			tabScrollY : [],
			WindowscrollY: 0,
			titleisHiding: false,

			scrollListenerIsSet: false,
			margeSrollTop: '',


			scrollTop: 0
		}
  },
  components: {
    HeaderTitle,
    HeaderFilters,

    HeaderFiltersMobile
  },
	watch: {
		// titleisHiding: function(val){
		// 	console.log(val)
		// },
		//
		scrollTop: function(oldScrollTop, newScrollTop){
			// console.log(oldScrollTop, newScrollTop)
			this.scrollCapture(oldScrollTop, newScrollTop)
		}
	},

  computed: {

  },
  methods: {
		emitFilters(filter) {
			this.$emit('set-filter', { filter: filter.filter  })


			window.scrollTo(0,0)
			this.$el.parentNode.scrollTo(0,0)
			
			this.titleisHiding = false
			this.tabScrollY = []
		},


		setScrollTopPortrait(e){
			this.scrollTop = e.target.scrollTop
		},

		setScrollTopLandscape(){
			this.scrollTop = window.scrollY
		},

    scrollCapture(oldScrollTop, newScrollTop){

      if ( newScrollTop > this.margeSrollTop ){

				if (this.tabScrollY.length > 5){

					const reducer = (accumulator, currentValue) => accumulator + currentValue;

					if (this.tabScrollY.reduce(reducer) > 0 ){ // to bottom
						this.tabScrollY.splice(0);
						this.titleisHiding = this.context != 'search' ? true : false
						this.tabScrollY = []
					}else{ 																		// to top
						this.tabScrollY.splice(0);
						this.titleisHiding = false
						this.tabScrollY = []
					}

					this.tabScrollY.push(oldScrollTop - newScrollTop)
				}
				this.tabScrollY.push(oldScrollTop - newScrollTop)
      }

      if ( newScrollTop < this.margeSrollTop ){
				this.titleisHiding = false
				this.tabScrollY = []
      }
    },

    setMargeScrollTop(){
			let headerTitleHeight =  Number(CSSvariables.headertitleheight.split('vw')[0])
			let headerFilterHeight = Number(CSSvariables.headerfilterheight.split('vw')[0])
			let headerFilterMarge = Number(CSSvariables.headerfiltermarge.split('vw')[0])
			let headerHeight = headerTitleHeight + headerFilterHeight + headerFilterMarge
			let margeScrollTop = headerHeight * window.innerWidth / 100
			
			this.margeSrollTop = margeScrollTop
    },
		onResize(){
			this.setMargeScrollTop()
		}
  },

  mounted(){
    // window.addEventListener('scroll', this.scrollCapture )
    window.addEventListener('resize', this.onResize)
    this.setMargeScrollTop()

    this.$el.parentNode.addEventListener('scroll', this.setScrollTopPortrait )
    window.addEventListener('scroll', this.setScrollTopLandscape )

    // console.log('header' + this.whatComputConfig, 'header' + '-' + this.context)

  },

  activated(){
    // console.log('ACTIVATED HEADER')

    this.titleisHiding = false
    this.tabScrollY = []
  },

  beforeDestroy(){
		window.removeEventListener('scroll', this.scrollCapture )
		window.removeEventListener('resize', this.onResize)


		window.scrollTo(0,0)
		this.$el.parentNode.scrollTo(0,0)
		
		this.titleisHiding = false
		this.tabScrollY = []
		this.scrollTop = 0
  }
}
</script>

<style lang="scss">
@import '@/css/variables';

.Header{
	position: relative;
	z-index: 400;

	&--margin{
		position: relative;
		width: 100%;
		height: calc(var(--header-title-font) + 1.3 * var(--header-filter-font) + var(--esp-small));

		&.isHiding{
			// height: calc( 1.3 * var(--header-filter-font) + var(--esp-small));
			// transition: height 0.3s;
			// transition-timing-function: ease-in;
		}
	}

	&--content{
		position: fixed;
		top: 0;
		width: var(--page-width);
		left: calc(var(--marge-page) / 2);

		transition: transform 0.2s;
		transition-timing-function: ease;

		&.isHiding{
			transform: translate(0, calc(-1 * var(--header-title-font) + var(--esp-small)));
			transition-timing-function: ease;
		}
	}
}


.Header--content.header-mobile{
	top: var(--menu-header-height);
	left: 0;
	width: 100%;

	background-color: var(--fourth-color);
	z-index: 10;
}

.Header--content.header-portrait{
	top: var(--menu-header-height);
	left: 0;
	width: 100%;

	background-color: var(--fourth-color);
	z-index: 10;
}


.Header--margin.header-search{
	height: calc(var(--header-title-font));
}

.Header--margin.header-portrait.header-decouvertes, .Header--margin.header-mobile.header-decouvertes{
	// height: 40vw;	
	height: calc(var(--header-title-font) + 1.3 * var(--header-filter-font) + var(--esp-small) + var(--esp-small) + var(--header-filter-font));
}
</style>

















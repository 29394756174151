<template>
  <div class="UniversEventSlider">
<!--     <div class="UniversEventSlider--text-container">
      <p class="UniversEventSlider--text-container--text">{{ contentSlider.text }}</p>
    </div> -->
    <div class="UniversEventSlider--slider-container" :class="'UniversEventSlider' + context">

      <div class="UniversEventSlider--slider-container--nav">
        <div class="UniversEventSlider--slider-container--nav--left" @click="toSlide(-1)"></div>
        <div class="UniversEventSlider--slider-container--nav--right" @click="toSlide(+1)"></div>
      </div>

      <div class="UniversEventSlider--slider-container--slider">


        <div class="UniversEventSlider--slider-container--slider--container"
        :style="{'left': whatPosition}"
        >

          <div class="UniversEventSlider--slider-container--slider--container--element" 
          v-for="(image, index) in contentSlider.images" 
          :key="image.filename">

            <img class="UniversEventSlider--slider-container--slider--container--element--image" 
            :src="image.thumb" 
            :data-srcset="image.srcset" 
            sizes="80vw" 
            @load="initTabsSlider(index, $event)">
  
          </div>

        </div>

      </div>
    
    </div>

    <div class="UniversEventSlider--description" v-html="contentSlider.description">

    </div>
  </div>
</template>

<script>
import LazyLoading from '@/directives/LazyLoading';

export default {
  name: 'UniversEventSlider',
  props: ['contentSlider', 'context'],
  directives: {
    LazyLoading,
  },
  data: function () {
   return {
     indexActiv : 0,
     tabPositionRaw: [],
     tabPosition: [],

     totalLoad: 0

   }
  },
  watch: {

  },
  computed:{
    whatPosition: function(){
      // console.log(this.tabPosition[this.indexActiv] * -1 + 'px')
      // return this.tabPosition[this.indexActiv] * -1 + 'px'

      return this.tabPosition[this.indexActiv] * -1 + 'px'

    },
    totalSlide: function(){
      return this.contentSlider.images.length
    }
  },
  methods: {

    toSlide: function(direction){
      let newIndex = this.indexActiv + direction

      if (newIndex < 0){
        // console.log('MINI')

      } else if (newIndex == this.totalSlide){
        // console.log('MAX')

      }else {
        this.indexActiv = newIndex
      }
    },

    // toLeft: function(){
    //   // console.log('TOLEFT')
    //   let newIndex = this.indexActiv - 1
    //   this.indexActiv = newIndex
    //   // this.indexActiv = this.indexActiv - 1
    // },
    // toRight: function(){
    //   // console.log('TORIGHT')

    //   let newIndex = this.indexActiv +1
    //   this.indexActiv = newIndex
    //   // this.indexActiv = this.indexActiv + 1
    // },

    initTabsSlider(index, e){

      // gérer data-srcset et 2eme load
      // if (e.target.data-srcset){} -> 1er load : ajouter dans le tabPositionRaw
      // if (!e.target.data-srcset){} -> 2eme load : ne rien faire (pour toute la suite de la méthode)
      
      if ( e.target.dataset.srcset ){

        if (!this.tabPositionRaw[0]){ this.tabPositionRaw[0] = 0 }

        this.tabPositionRaw[index + 1] = e.target.getBoundingClientRect().width
        this.totalLoad = this.totalLoad + 1

        if (this.totalLoad == this.totalSlide){

          // let security = 25
          // let lastElPosRaw = this.tabPositionRaw[this.totalSlide - 2] - (this.$el.scrollWidth - this.tabPositionRaw[this.totalSlide - 1]) - security
          // this.tabPositionRaw[this.totalSlide - 1] = lastElPosRaw

          this.tabPositionRaw.reduce( (prev, curr, i) => this.$set(this.tabPosition , i,  prev + curr), 0)
          // console.log(this.tabPosition)        
        }

        e.target.srcset = e.target.dataset.srcset
      }

      e.target.removeAttribute('data-srcset')
    },
  }
 
}
</script>

<style lang="scss">
@import '@/css/variables';

.UniversEventSlider{
  position: relative;
  width: 100%;

  &--description{
    font-size: var(--univers-font-labeur-rgl);
    padding: var(--esp-med);

    & p {
      margin-bottom: var(--esp-med);
    }

    & p:last-child{
      margin-bottom: 0;
    }

    & a {
      text-decoration: underline;
    }
  }

  &--slider-container{
    position: relative;
    height: 50vh;
  }
}

.UniversEventSlider--slider-container--slider{
  height: 100%;
  overflow: hidden;

  &--container{

    display: flex;
    height: 100%;

    transition: left 0.3s ease;
    position: relative;

      &--element{
        height: 100%;
        position: relative;

        &--image{
          height: 100%;
          width: auto;
        }
      }
  }
}

.UniversEventSlider--slider-container--nav{

  position: absolute;

  width: 100%;
  height: 100%;
  display: flex;

  z-index: 3;

  &--left{
    height: 100%;
    width: 50%;

    cursor: var(--cursor-arrow-left);
  }

  &--right{
    height: 100%;
    width: 50%;
    left: 50%;

    cursor: var(--cursor-arrow-right);
  }

}



.UniversEventSlider--slider-container.UniversEventSlider-portrait{
  height: 45vh;
}

.UniversEventSlider--slider-container.UniversEventSlider-mobile{
  height: 60vw;
}

</style>
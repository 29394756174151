<template>
  <div class="UniversWorkshop">


    <div class="UniversWorkshop--drawer-container">

      <div class="UniversWorkshop--drawer-container--intro" v-html="content.intro"></div>

      <UniversWorkshopDrawer
      v-for="(element, index) in content.structure" :key="index"
      :style="{'background-color' : whatColor(index)}"
      @send-toggle="sendToggle"
      :content="element"
      :context="whatComputConfig"
      :index="index"
      :leftScale="LeftScale"
      :topPositionThumb="topPositionThumb">

        <transition name="toggleDrawer" mode="out-in">

        <UniversWorkshopContent 
        v-if="sliderIsOpen[index]"
        :content="element[1]"
        :context="whatComputConfig"
        :contentFigure="element[2]">
        </UniversWorkshopContent>

        </transition>

      </UniversWorkshopDrawer>

      <div class="UniversWorkshop--drawer-container--outro"
      :style="{'background-color' : whatColor(content.structure.length)}"
      v-html="content.outro"></div>

    </div>

  </div>
</template>

<script>
import UniversWorkshopDrawer from '@/components/univers/universworkshop/UniversWorkshopDrawer.vue'
import UniversWorkshopContent from '@/components/univers/universworkshop/UniversWorkshopContent.vue'

import clientConfigs from '@/mixins/clientConfigs.js'
import smoothscroll from 'smoothscroll-polyfill';

export default {
  name: 'UniversWorkshop',
  mixins: [clientConfigs],
  components: {
    UniversWorkshopDrawer,
    UniversWorkshopContent
  },
  props: ['content'],
  data: function(){
    return {
      tabIsVisible: [],

      LeftScale: '',
      topPositionThumb: '',

      // thumbWidth: ''
    }
  },
  computed:{

    sliderIsOpen: function() {
        return this.tabIsVisible.map(function(item) {
            return item;
        });
    }

  },
  methods: {

    initLeftPosition: function(){  /** RESIZE A FAIRE */
      let windowWidth = window.innerWidth
      let pageWidth = Number(window.getComputedStyle(document.documentElement).getPropertyValue('--page-width').split('vw')[0]) * windowWidth / 100;
      // let thumbWidth = 25 * pageWidth / 100
      let thumbWidth = Number(window.getComputedStyle(document.documentElement).getPropertyValue('--univers-thumb-workshop-width').split('vw')[0]) * pageWidth / 100
      let security = 10 * pageWidth / 100

      this.topPositionThumb =  - 1 * thumbWidth / 3
      this.LeftScale = pageWidth - thumbWidth - security
    },   
    whatColor: function(index){
      if (index%2 == 0){
        return 'var(--third-color-lighten)'
      }else{
        return 'var(--third-color)'
      }
    },
    initTabIsVisible: function(){
      this.content.structure.forEach((element, index) => this.$set(this.tabIsVisible, index, false) )
    },
    sendToggle: function(index){
      this.toggleSlider(index)
    },
    toggleSlider: function(index){
   
      let elementTitle = this.$el.querySelectorAll('.UniversWorkshopDrawer')[index].querySelector('.UniversWorkshopDrawer--title')
      let elementBottomPosition = elementTitle.getBoundingClientRect().bottom
      let RemoveToScrollTop = window.innerHeight - elementBottomPosition

      if ( this.tabIsVisible[index] == true ){

        this.$el.parentNode.scrollTo({
          top: this.$el.parentNode.scrollTop - RemoveToScrollTop,
          left: 0,
          behavior: 'smooth'
        });
      }

      this.$set(this.tabIsVisible, index, !this.tabIsVisible[index])

      /* 
      * TRANSITION THUMB 
      *   1. ouverture drawer -> déplacement vers la position figure dans le DrawerContent  
      *   2. fermeture drawer -> reset / initialisation de la position de thumb
      */
     
      if (this.whatComputConfig != '-mobile'){

      if ( this.tabIsVisible[index] == true){ // ouverture
        
      setTimeout(() => { 
        let elementClicked = this.$el.querySelectorAll('.UniversWorkshopDrawer')[index]
        let elementClickedContent = elementClicked.querySelector('.UniversWorkshopContent')
        let elementClickedFigure = elementClickedContent.querySelector('img')
        let elementClickedThumb = elementClicked.querySelector('.UniversWorkshopDrawer--img-container')

        let parentTop = this.$el.querySelectorAll('.UniversWorkshopDrawer')[index].getBoundingClientRect().top
        let parentLeft = this.$el.querySelectorAll('.UniversWorkshopDrawer')[index].getBoundingClientRect().left

        let figureTop = elementClickedFigure.getBoundingClientRect().top
        let figureLeft = elementClickedFigure.getBoundingClientRect().left

        let newPositionTop = figureTop - parentTop
        let newPositionLeft = figureLeft - parentLeft 

        elementClickedThumb.style.top = newPositionTop + 'px'
        elementClickedThumb.style.left = newPositionLeft + 'px'

        setTimeout(() => { 

          elementClickedFigure.style.opacity = 1 
          elementClickedThumb.style.opacity = 0

        }, 450);

      }, 300); 

      }else { // fermeture
        
        let elementClicked = this.$el.querySelectorAll('.UniversWorkshopDrawer')[index]
        let elementClickedThumb = elementClicked.querySelector('.UniversWorkshopDrawer--img-container') 

        elementClickedThumb.style.transition = 'none';

        setTimeout(() => { 
          elementClickedThumb.style.left = this.$tools.getRandom(this.LeftScale) + 'px'
          // elementClickedThumb.style.top = '-50px'
          elementClickedThumb.style.top = this.topPositionThumb + 'px'
          elementClickedThumb.style.opacity = 1

          setTimeout(() => {  
            elementClickedThumb.style.transition = 'all .45s ease'; 
            elementClickedThumb.style.opacity = '' 
          }, 20);

        }, 450);
      }   

      }
    },
    resetThumbs: function(){
      let elementToClick = this.$el.querySelectorAll('.UniversWorkshopDrawer')
      elementToClick.forEach((element) => {
        let elementToClickThumb = element.querySelector('.UniversWorkshopDrawer--img-container')

          elementToClickThumb.style.transition = 'all .45s ease'; 
          elementToClickThumb.style.opacity = '' 
      })

    },
    onResize: function(){ 
      if (this.whatComputConfig != '-mobile'){

      this.tabIsVisible.forEach((element, index) => {
        this.$set(this.tabIsVisible, index, false)
      })

      this.initLeftPosition() 
      this.initTabIsVisible()

      this.resetThumbs()
      }
    }
  },
  created(){
    smoothscroll.polyfill();
    this.initTabIsVisible()
    this.initLeftPosition()

    window.addEventListener('resize', this.onResize)
  },
  beforeDestroy(){
    window.removeEventListener('resize', this.onResize)
  }
  
}
</script>

<style lang="scss">
@import '@/css/variables';

@import '@/css/transition';

// .toggleDrawer-enter{
//   opacity: 0;

//   position: absolute;
//   max-height: 0px;
// }

// .toggleDrawer-enter-active{
//   // transition: opacity 0.6s;

//   transition: all 0.6s;
//   opacity: 1;

//   max-height: 2000px;
// }


// .toggleDrawer-leave-active{
//   // transition: opacity 0.2s;

//   transition: all 0.2s;
// }

// .toggleDrawer-leave-to{
//   opacity: 0;
// }

.toggleDrawer-enter-active, .toggleDrawer-leave-active {
  transition: all .45s ease;
  max-height: 500;
}

.toggleDrawer-leave-active{
  transition: all .45s ease;
}

.toggleDrawer-enter, .toggleDrawer-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  max-height: 0px;
}


.UniversWorkshop{
  height: 100%;

  &--drawer-container{
    overflow: hidden;

    &--intro{
      font-size: var(--univers-font-labeur-rgl);
      text-align: center;
      padding: var(--esp-big);
    }
    &--outro{
      font-size: var(--univers-font-labeur-rgl);
      text-align: center;
      padding: var(--esp-big);
    }
  }
}



</style>
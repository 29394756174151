<template>
  <div class="UniversEventDrawer" :style="{'background-color' : whatColor()}">

    <div class="UniversEventDrawer--container" @click="toggleSlider(index)" @mouseenter="mouseEnter()" @mouseleave="mouseLeave()">
      
      <div class="UniversEventDrawer--container--text-container">
        
        <p class="UniversEventDrawer--container--text-container--date">{{ content.date2 }}</p>
        <p class="UniversEventDrawer--container--text-container--text">{{ content.title }}</p>

      </div>

      <transition name="fadeView" appear mode="out-in">

      <div 
      class="UniversEventDrawer--container--thumb-container"
      v-if="thumbIsActive && !sliderIsOpen"
      :style="whatThumbPosition">
      
      <!-- :style="{'transform' : whatTranslateLeft()}" -->
        
        <img class="UniversEventDrawer--container--thumb-container--img" 
        v-lazyloading
        :src="content.cover[0].thumb" 
        :data-srcset="content.cover[0].srcset" 
        sizes="80vw">

      </div>

      </transition>

    </div>

    <slot></slot>

  </div>
</template>

<script>

import LazyLoading from '@/directives/LazyLoading';

export default {
  name: 'UniversEventDrawer',
  props: ['content', 'index', 'context', 'annonceDisplay'],
  directives: {
    LazyLoading,
  },
  data: function () {
    return {
      sliderIsOpen: '',
      thumbIsActive: false,

      LeftScale: ''
    }
  },
  computed:{
    whatThumbPosition: function(){
      return {
        left: this.$tools.getRandom(this.LeftScale) + 'px',
      }
    },
  },

  methods: {

    initSliderIsOpen: function(){
      if (this.index == 0 && this.annonceDisplay == 'false'){
        // console.log(this.index, true)
        this.sliderIsOpen = true
      }else {
        // console.log(this.index, false)
        this.sliderIsOpen = false
      }

    },
    initLeftPosition: function(){ /** RESIZE */
      let windowWidth = window.innerWidth
      let pageWidth = Number(window.getComputedStyle(document.documentElement).getPropertyValue('--page-width').split('vw')[0]) * windowWidth / 100;
      let thumbWidth = Number(window.getComputedStyle(document.documentElement).getPropertyValue('--univers-thumb-event-width').split('vw')[0]) * pageWidth / 100
      let security = 10 * pageWidth / 100

      this.LeftScale = pageWidth - thumbWidth - security
    },
    mouseEnter: function(){
      if (this.context != '-mobile'){
        this.thumbIsActive = true
      }
    },
    mouseLeave: function(){
      if (this.context != '-mobile'){
        this.thumbIsActive = false
      }
    },
    whatColor: function(){
      if (this.index%2 == 0){
        return 'var(--third-color)'
      }else{
        return 'var(--third-color-lighten)'
      }
    },
    toggleSlider: function(){
      // console.log('toggleSlider')

      // this.sliderIsOpen = true
      this.$emit('send-toggle', this.index)

      if (this.sliderIsOpen == false){
        this.sliderIsOpen = true
      }else {
        setTimeout(() => { 
          this.sliderIsOpen = false
         }, 300);
      }
    },
    onResize: function(){
      this.initLeftPosition()
    }
  },

  created(){
    this.initSliderIsOpen()
    this.initLeftPosition()
    window.addEventListener('resize', this.onResize)
  },
  mounted(){
  },
  beforeDestroy(){
    window.removeEventListener('resize', this.onResize)
  }
}
</script>

<style lang="scss">
@import '@/css/variables';

.UniversEventDrawer{
  position: relative;
  width: 100%;
  // z-index: 10;

  &--container{
    z-index: 10;
    position: relative;
    padding: var(--esp-med);
    padding-bottom: var(--esp-small);

    cursor: pointer;

    &--text-container{
      z-index: 10;

      &--date{
        font-family: 'vd-reg';
        font-size: var(--univers-font-date);
        line-height: 0.9;

        z-index: 600;

        margin-bottom: 0.3em;
      }

      &--text{
        position: relative;
        font-size: var(--univers-font-labeur-title);
        line-height: 0.9;

        z-index: 600;
      }
    }
  
    &--thumb-container{
      position: absolute;
      width: var(--univers-thumb-event-width);
      top: 50%;
      
      pointer-events: none;

      z-index: 500;
      transform: translate(0, -50%);

      &--img{
        width: 100%;

      }
    }
  }
}





</style>
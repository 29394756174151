<template>
  <div class="DrawerStore">

    <div class="DrawerStore--filters">
      
<!--       <p>
        <span 
        v-for="(filter, index) in content.filters" 
        :key="index"
        @click="setFilter(index, filter)"
        >
        <template v-if="index < content.filters.length - 1">{{filter}}, </template>
        <template v-else>{{filter}}</template>
        </span>

      </p> -->

      <div class="DrawerStore--filters--container"
      v-for="(filterCategorie, index) in content.filters" 
      :key="index">

<!--       <template v-if="index == 0"><div class="DrawerStore--filters--container--title">France</div></template>
      <template v-else><div class="DrawerStore--filters--container--title">World</div></template> -->

      <template v-if="index == 0"><span class="DrawerStore--filters--container--title">France</span> ➔ </template>
      <template v-else><span class="DrawerStore--filters--container--title">World</span> ➔ </template>

        <span v-for="(filter, indexFilter) in filterCategorie"
        :key="indexFilter"
        @click="setFilter(index, filter)"
        >
          <template v-if="indexFilter < filterCategorie.length - 1">{{filter}}, </template>
          <template v-else>{{filter}}</template>
        </span>

      </div>

    </div>

    <DrawerStoreCategorie
    v-for="(categorie, index) in content.content"
    :key="index"
    :categorie="index"
    :content="categorie"
    :context="context"
    ></DrawerStoreCategorie>

  </div>
</template>

<script>
import DrawerStoreCategorie from '@/components/univers/universteam/drawerstore/DrawerStoreCategorie.vue'

import smoothscroll from 'smoothscroll-polyfill';

export default {
  name: 'DrawerStore',
  props: ['content', 'context'],
  components: {
    DrawerStoreCategorie
  },
  computed:{

  },
  methods: {
    setFilter: function(index, filter){
      // console.log(index, filter)

      let cible = this.$el.querySelector(`[data-anchor="${filter}"]`)

      // let newPosition = cible.offsetTop - ( 13 * window.innerHeight / 100)
      let HeaderMarginHeight = document.querySelector('.Header--margin').getBoundingClientRect().height
      let newPosition = cible.offsetTop - HeaderMarginHeight
      
      // this.$el.parentNode.parentNode.parentNode.parentNode.scrollTo({
      window.scrollTo({
        top: newPosition,
        left: 0,
        behavior: 'smooth'
      });

      // cible.scrollIntoView({ behavior: 'smooth' });

    }

  },
  created(){
    smoothscroll.polyfill();
  }
  
}
</script>

<style lang="scss">

.DrawerStore{

  width: 100%;

  &--filters{
    font-size: var(--univers-font-labeur-rgl);
    background-color: var(--third-color-lighten);

    padding-top: var(--esp-big);
    padding-bottom: var(--esp-big);

    & div:first-child {
      margin-bottom: var(--esp-med);
    }

    &--container{

      margin-left: var(--esp-big);
      margin-right: var(--esp-big);

      // padding-top: var(--esp-big);
      // padding-bottom: var(--esp-big);

      &--title{
        text-align: center;
        text-decoration: underline;
      }
    }
  }

  & span{
    cursor: pointer;
    
  }



}





</style>
<template>
	<div class="UniversEventAnnonce">


		<div class="UniversEventAnnonce--text-container">
			<div class="UniversEventAnnonce--text-container--title">{{content.title}}</div>
			<div class="UniversEventAnnonce--text-container--text2" v-html="content.text2"></div>
		</div>
<!-- 		<img class="UniversEventAnnonce--img-container" :src="content.cover.thumb" :srcset="content.cover.srcset" sizes="80vw"> -->

		<div class="UniversEventAnnonce--img-container" :class="'UniversEventAnnonce' + context">

		<img class="UniversEventAnnonce--img-container--img" :src="content.covers[0].thumb" :srcset="content.covers[0].srcset" sizes="80vw">
		<img class="UniversEventAnnonce--img-container--img" :src="content.covers[1].thumb" :srcset="content.covers[1].srcset" sizes="80vw">

		</div>

		<div class="UniversEventAnnonce--description" v-html="content.description"></div>

	</div>
</template>

<script>
export default {
	name: 'UniversEventAnnonce',
	props: ['content', 'context'],
	computed:{

	},
	methods: {

	},
	mounted(){
		// console.log(this.content)
	}

}
</script>

<style lang="scss">
@import '@/css/variables';

.UniversEventAnnonce{
	width: 100%;
	
	&--text-container{
    margin: var(--esp-big);

		&--title{
			font-size: var(--univers-font-labeur-title);
			text-align: center;	
			line-height: 0.9;
		}
		&--text{

			font-family: 'vd-ita';	
			font-size: calc( 0.8 * var(--univers-font-labeur-rgl));
			text-align: center;		
		}

		&--text2{
			// font-family: 'vd-ita';	
			font-size: var(--univers-font-labeur-rgl);
			text-align: center;		

			& a {
				text-decoration: underline;
			}
		}
	}

	&--description{
		font-size: var(--univers-font-labeur-rgl);
		padding: var(--esp-med);

		& a {
			text-decoration: underline;
		}
	}

	&--img-container{
		width: 100%;
		display: flex;
		height: 60vh;

		&--img{
			width: 50%;
			height: 100%;
			object-fit: cover;
		}
	}
}


.UniversEventAnnonce--img-container.UniversEventAnnonce-portrait{
	height: 45vh;
}

.UniversEventAnnonce--img-container.UniversEventAnnonce-mobile{
	height: 60vw;
}

</style>
<template>
  <div class="HeaderTitle" :class="[$route.name]">
		<div class="HeaderTitle--title">{{ title }}</div>
  </div>
</template>

<script>
export default {
  name: 'HeaderTitle',
  props: ['title']
}
</script>

<style lang="scss">
@import '@/css/variables';

.HeaderTitle{

	// font-size: $header-title-font;
	font-size: var(--header-title-font);
	// font-size: 26vw;

	width: 100%;
	line-height: 1;

	position: relative;


	&--title{
		display: inline-block;
		position: relative;
		// height: 10vw;
		height : var(--header-title-font);
		top: 0px;
		transform: translate(-50%, 50%);
		left: 50%;
		justify-content: center;

		white-space: nowrap;

		height: 10px;

		// overflow: hidden;

	}

}

.HeaderTitle.art{
  background-color: var(--first-color);
}

.HeaderTitle.amateur{
  background-color: var(--second-color);
}

.HeaderTitle.decouvertes{
  background-color: var(--third-color);
}

</style>

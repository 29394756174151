<template>
  <div class="HeaderFilters" :class="[$route.name, whatConfig]">

    <div 
    v-for="filter in filtersTab" 
    :key="filter.name" 
    @click="emitFilters(filter.value)"
    class="HeaderFilters--element"
    :class="[$route.name, filter.value === activeFilter ? 'active' : '', whatColomns, whatConfig]" 
    > 
      <p>{{ filter.name }}</p>
    </div>

  </div>
</template>

<script>

export default {
  name: 'HeaderFilters',
  props: ['filtersTab', 'activeFilter'],
  computed:{
    ClientFormat: function(){ return this.$client.state.format},
    ClientDevice: function(){ return this.$client.state.device},

    whatColomns: function(){ 
      if (this.filtersTab.length == 4){
        return 'four-filters'
      }
      return 'two-filters'
    },

    whatConfig: function(){
      if (this.filtersTab.length == 4){
        if ( this.ClientFormat == 'portrait'){
          return 'headerfilter-mobile'
        }else {
          return ''
        }
      }else {
        return ''
      }
    }

  },

  methods: {
    emitFilters(filter) {
      // console.log(filter)
      this.$emit('set-filter', { filter })
    },
  },

  mounted(){

  }
}
</script>


<style lang="scss">
@import '@/css/variables';

.HeaderFilters{

	font-size: var(--header-filter-font);
	display: flex;
	flex-wrap: nowrap;
  justify-content: space-evenly;

  height: calc(1.3 * var(--header-filter-font));

  padding-bottom: var(--esp-small);

  cursor: pointer;

	&--element{
		text-align: center;

    & p{
      transform: translate(0, 18%);
    }
	}
}

.two-filters{
  width: calc( ( var(--page-width) - (3 * var(--esp-small)) ) / 2 );
}

.four-filters{
  width: calc( ( var(--page-width) - (5 * var(--esp-small)) ) / 4 );
}




.HeaderFilters.art{
  background-color: var(--first-color);
}

.HeaderFilters.amateur{
  background-color: var(--second-color);
}

.HeaderFilters.decouvertes{
  background-color: var(--third-color);
}


.HeaderFilters--element.art{
  background-color: var(--first-color-lighten);
}

.HeaderFilters--element.amateur{
  background-color: var(--second-color-lighten);
}

.HeaderFilters--element.decouvertes{
  background-color: var(--third-color-lighten);
}




.HeaderFilters--element.active.art{
  color: var(--first-color);
  background-color: black;
  cursor: default;

}

.HeaderFilters--element.active.amateur{
  color: var(--second-color);
  background-color: black;
  cursor: default;
}

.HeaderFilters--element.active.decouvertes{
  color: var(--third-color);
  background-color: black;
  cursor: default;
}

// .two-filters{
//   width: calc( ( #{$page-width} - (3 * #{ $header-title-marge }) ) / 2 );
// }

// .four-filters{
//   width: calc( ( #{$page-width} - (5 * #{ $header-title-marge }) ) / 4 );
// }








.HeaderFilters.headerfilter-mobile{

  font-size: var(--header-filter-font);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;

  // padding-bottom: var(--esp-small);
  padding-bottom: 0;
  
  height: calc( 2 * (1.3 * var(--header-filter-font)) + (2*var(--esp-small)));

}

.HeaderFilters--element.headerfilter-mobile{
  text-align: center;
  height: calc(1.3 * var(--header-filter-font)); 
}


.two-filters{
  width: calc( ( var(--page-width) - (3 * var(--esp-small)) ) / 2 );
}

.four-filters.headerfilter-mobile{
  width: calc( ( var(--page-width) - (3 * var(--esp-small)) ) / 2 );
}












</style>

<template>
  <div class="DrawerCgv">
    <div class="DrawerCgv--content" v-html="content.content"></div>

  </div>


</template>

<script>
export default {
  name: 'DrawerCgv',
  props: ['content', 'context'],
  computed:{

  },
  methods: {

  },
  created(){
    console.log(this.content)
  }
  
}
</script>

<style lang="scss">



.DrawerCgv{
  // width: 100%;
  // font-size: calc(var(--univers-font-labeur-rgl) / 5 * 3);
  font-size: var(--univers-font-labeur-rgl);
  padding: var(--esp-big);

  &--content{
    column-count: 2;

    & p {
      padding-bottom: 1vw;
    }
  }
}


</style>
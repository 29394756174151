<template>
  <div class="DraweDiffusion">

    <div class="DraweDiffusion--section"
    v-for="(section, index) in content"
    :key="index">
      <div class="DraweDiffusion--section--title">
        <!-- <template v-if="section.title == 'Texte' && this.$route.params.language == 'fr'">
        Texte
        </template>
        <template v-if="section.title == 'Images' && this.$route.params.language == 'fr'">
        Images
        </template>
        <template v-if="section.title == 'Texte' && this.$route.params.language == 'en'">
        Texts
        </template>
        <template v-if="section.title == 'Images' && this.$route.params.language == 'en'">
        Images
        </template> -->

        {{section.title}}
      </div>
      <div class="DraweDiffusion--section--content" v-html="section.content"></div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'DraweDiffusion',
  props: ['content'],
  computed:{

  },
  methods: {

  },
  created(){
    // console.log(this.content)
  }
  
}
</script>

<style lang="scss">

.DraweDiffusion{

  font-size: var(--univers-font-labeur-rgl);
  padding: var(--esp-big);
  text-align: center;

  display: flex;
  
  &--section{
    width: 50%;


    &--title{
      margin-bottom: var(--esp-big);
      text-decoration: underline;

    }

    &--content{

      overflow-wrap: anywhere;

      & a {
        text-decoration: underline;
      }

      & p {
        margin-bottom: var(--esp-big);
      }
      & p:last-child {
        margin-bottom: 0;
      }  
      & h3{
        margin-bottom: var(--esp-small);
      }


    }

  }	
}





</style>
<template>
  <div class="DrawerTeam">

  <div class="DrawerTeam--list">

	<div
	class="DrawerTeam--list--element"
  :class="['grid-element-' + (index + 1), 'DrawerTeam' + context]"
	v-for="(element,index) in content"
	:key="index">
	<div class="DrawerTeam--list--element--title" v-html="element[0]"></div>
	<div class="DrawerTeam--list--element--content" v-html="element[1]"></div>
	</div>

  </div>

  </div>


</template>

<script>
export default {
  name: 'DrawerTeam',
  props: ['content', 'context'],
  computed:{

  },
  methods: {

  }
  
}
</script>

<style lang="scss">



.DrawerTeam{
  width: 100%;

  &--list{
    display: flex;
    flex-wrap: wrap;

    // grid-template-columns: repeat(5, 1fr);
    // grid-gap: var(--esp-big);
    // grid-auto-rows: minmax(100px, auto);

     padding: var(--esp-big);

    &--element{
      // width: 33%;
      width: 50%;
      margin-bottom: calc(1.5 * var(--esp-big));

      &--title{
        font-size: var(--univers-font-labeur-rgl); 
        font-family: 'vd-reg';
        text-align: center;
        margin-bottom: var(--esp-small);

        padding-left: var(--esp-med);
        padding-right: var(--esp-med);

        overflow-wrap: anywhere;
        @supports ( hyphens: auto ) {
          hyphens: auto;
          -webkit-hyphens: auto;
          -moz-hyphens: auto;
          -ms-hyphens: auto;
        }
      }

      &--content{
        font-family: 'oli';
        font-size: var(--univers-font-labeur-rgl);
        text-align: center;

        padding-left: var(--esp-med);
        padding-right: var(--esp-med);

        & a::after{
          // content: url(~@/assets/icone/arrow_link.svg);
          padding-left: calc(var(--univers-font-labeur-rgl) / 3 * 2);
          // height: 50%;
          position: relative;

          margin-left: var(--esp-small);

          content: "";
          height: calc(var(--univers-font-labeur-rgl) / 3 * 2);
          width: calc(var(--univers-font-labeur-rgl) / 3 * 2);
          background-size: calc(var(--univers-font-labeur-rgl) / 3 * 2) calc(var(--univers-font-labeur-rgl) / 3 * 2);
          background-image: url(~@/assets/icone/arrow_link.svg);
          background-repeat: no-repeat;

        }

        & a {
          // text-decoration: underline;
          // background-color: red;


        }
      }
    }
  }
}

.grid-element-1{
  width: 100%;
}

.grid-element-2{
  width: 50%;
  height: auto;
}

.grid-element-3{
  width: 50%;
}



.DrawerTeam--list--element.DrawerTeam-portrait{
  width: 50%;
}
.DrawerTeam--list--element.DrawerTeam-mobile{
  width: 50%;
}

.grid-element-1.DrawerTeam-portrait{
  width: 100%;
}

.grid-element-1.DrawerTeam-mobile{
  width: 100%;
}


</style>
<template>
  <div class="DraweStoreCategorie">

    <!-- <div class="DraweStoreCategorie--title">{{categorie | capitalize}}</div> -->
    <div class="DraweStoreCategorie--title">{{categorie | capitalize}}</div>

      <div class="DraweStoreCategorie--content" 
      :class="'DraweStoreCategorie' + context"
      v-for="(categorie, index) in content" 
      :key="index"
      :style="{'background-color' : whatColor(index)}"
      >
       
        <div 
        class="DraweStoreCategorie--content--localisation" 
        :class="'DraweStoreCategorie' + context"
        :data-anchor="categorie.localisation"
        v-html="categorie.localisation">
        </div>

        <div class="DraweStoreCategorie--content--places-container"
        :class="'DraweStoreCategorie' + context">

          <div 
          class="DraweStoreCategorie--content--places-container--place"
          :class="'DraweStoreCategorie' + context"
          v-for="place in (categorie.placeKirby)" 
          :key="place" 
          v-html="place">
          </div>
        </div>     

     </div>


  </div>
</template>

<script>
export default {
  name: 'DraweStoreCategorie',
  props: ['categorie', 'content', 'context'],
  filters: {
    capitalize: function (value) {
      if (!value) return ''
      value = value.toString()
      return value.charAt(0).toUpperCase() + value.slice(1)
    }
  },
  computed:{

  },
  methods: {

    whatColor: function(index){
      if (index%2 == 0){
        return 'var(--third-color-lighten)'
      }else{
        return 'var(--third-color)'
      }
    },

  },
  created(){
    // console.log(this.context)
  }
  
}
</script>

<style lang="scss">

.DraweStoreCategorie{

  width: 100%;

  &--title{
    font-size: var(--univers-font-labeur-rgl); 
    text-align: center;
    margin-top: var(--esp-big);
    margin-bottom: var(--esp-big);
    text-decoration: underline;
  }

  &--content{

    padding: var(--esp-big);
    padding-bottom: var(--esp-small);

    display: flex;
   

    // width: 100%;
    width: calc(100% - (2 * var(--esp-big)));


    &--localisation{
      font-size: var(--univers-font-labeur-rgl);
      width: 20%;
      // background-color: orange;

    }

    &--places-container{

      // width: 80%;
      width: calc(80%);
      // background-color: red;

      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;

      &--place{
        width: 22.5%;
        margin-left: 2%;

      }
    }
  }
}


.DraweStoreCategorie--content--places-container--place{

  & ol {

    margin-bottom: var(--esp-med);

    & li {

      // background-color: green;
      font-family: 'vd-reg';
      font-size: var(--univers-store-font-rgl);
      padding-right: var(--esp-big);
    }

    & li:first-child {
      font-family: 'oli';
      font-size: var(--univers-store-font-bold);
      margin-bottom: 0.3vw;

    } 
  }
}


.DraweStoreCategorie--content.DraweStoreCategorie-mobile{
  display: block;
}

.DraweStoreCategorie--content--localisation.DraweStoreCategorie-mobile{
  text-align: center;
  width: 100%;
  margin-bottom: var(--esp-big);
}

.DraweStoreCategorie--content--places-container.DraweStoreCategorie-mobile{
  width: 100%;
  // background-color: red;
}

.DraweStoreCategorie--content--places-container--place.DraweStoreCategorie-portrait{
  width: 31%;
  margin-left: 2%;
}

.DraweStoreCategorie--content--places-container--place.DraweStoreCategorie-mobile{
  width: 48%;
  margin-left: 0%;

}




</style>
<template>
  <div class="UniversWorkshopDrawer">

    <div class="UniversWorkshopDrawer--title" 
    v-html="content[0]" 
    @click="toggleSlider(index)"
    @mouseenter="mouseEnter"
    @mouseleave="mouseLeave"
    >
    </div>

    <transition name="fadeView" appear mode="out-in">

    <div class="UniversWorkshopDrawer--img-container" 
    v-show="thumbIsActive"
    :style="whatThumbPosition">
      <img class="UniversWorkshopDrawer--img-container--img" :src="content[2]">
    </div>

    </transition>

    <slot></slot>

  </div>
</template>

<script>

import LazyLoading from '@/directives/LazyLoading';

export default {
  name: 'UniversWorkshopDrawer',
  props: ['content', 'index', 'context', 'leftScale', 'topPositionThumb', 'thumbWidth'],
  directives: {
    LazyLoading,
  },
  data: function () {
    return {
      sliderIsOpen: false,
      thumbIsActive: false,

      // initLeftPosition: this.$tools.getRandom(this.leftScale)
      // LeftPosition: this.$tools.getRandom(this.leftScale)
      LeftPosition: ''
    }
  },
  computed:{
    whatThumbPosition: function(){
      return {
        left: this.LeftPosition + 'px',
        top: this.topPositionThumb + 'px'
      }
    },

  },
  methods: {
    initLeftPosition: function(){
      this.LeftPosition = this.$tools.getRandom(this.leftScale)
    },

    mouseEnter: function(){
      if (this.context != '-mobile'){

        if ( this.sliderIsOpen == false ){
          this.thumbIsActive = true
        }
      }
    },
    mouseLeave: function(){
      if (this.context != '-mobile'){

        if (this.sliderIsOpen == true){
          setTimeout(() => { this.thumbIsActive = false }, 450);
        }else {
          this.thumbIsActive = false
        }

      }
    },
    toggleSlider: function(){
      // console.log('toggleSlider', this.sliderIsOpen)

      if (this.context != '-mobile'){

        if ( this.sliderIsOpen == true){
          this.thumbIsActive = true
        }
      }

      this.sliderIsOpen = !this.sliderIsOpen
      this.$emit('send-toggle', this.index)
    },
    onResize: function(){ 
      this.initLeftPosition() 
      this.sliderIsOpen = false
    }
  },
  created(){
    this.initLeftPosition()
    window.addEventListener('resize', this.onResize)
  },
  beforeDestroy(){
    window.removeEventListener('resize', this.onResize)
  }
}
</script>

<style lang="scss">
@import '@/css/variables';

.UniversWorkshopDrawer{
  position: relative;

  &--title{
    position: relative;
    font-size: var(--header-title-font);
    text-align: center;
    text-decoration: underline;
    cursor: pointer;
    padding: var(--esp-big);
    z-index: 100;
  }

  &--img-container{

    transition: all .45s ease;

    pointer-events: none;
    z-index: 50;
    width: var(--univers-thumb-workshop-width);

    position: absolute;

    top: 0;
    
    &--img{
      width: 100%;
      pointer-events: none;
    }
  }
}

</style>
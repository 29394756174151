<template>
	<div class="UniversEvent">

    <div class="UniversEvent--annonce-container" v-if="content.annonceDisplay == 'true'">

      <div v-for="(annonce, index) in content.annonce" :key="index">
        <UniversEventAnnonce :content="annonce" :context="whatComputConfig"></UniversEventAnnonce>
      </div>

    </div>


    <div class="UniversEvent--archive-container">

      <UniversEventDrawer
      v-for="(element, index) in content.archive" 
      :key="index"
      :content="element"
      :index="index"
      :context="whatComputConfig"
      :annonceDisplay="content.annonceDisplay"
      @send-toggle="sendToggle">

        <transition name="toggleDrawer" mode="out-in">

        <UniversEventSlider 
        v-if="sliderIsOpen[index]" 
        :contentSlider="content.archive[index].slider"
        :context="whatComputConfig">
        </UniversEventSlider> 
         
        </transition>     

      </UniversEventDrawer>

    </div>

    <div class="UniversEvent--outro"
    :style="{'background-color' : whatColor(content.archive.length)}">
      <template v-if="this.$route.params.language == 'fr'">
      Suivez tous nos événements : <a :href="content.link_newsletter" target="_blank">inscrivez-vous à la newsletter</a>
      </template>

      <template v-if="this.$route.params.language == 'en'">
       Follow all our events: <a :href="content.link_newsletter" target="_blank">subscribe to the newsletter</a>
      </template>
      
    </div>

  </div>
</template>

<script>
import UniversEventDrawer from '@/components/univers/universevent/UniversEventDrawer.vue'
import UniversEventSlider from '@/components/univers/universevent/UniversEventSlider.vue'
import UniversEventAnnonce from '@/components/univers/universevent/UniversEventAnnonce.vue'

import LazyLoading from '@/directives/LazyLoading';
import clientConfigs from '@/mixins/clientConfigs.js'

export default {
  name: 'UniversEvent',
  mixins: [clientConfigs],
  props: ['content'],
  data: function () {
	return {
    tabIsVisible: [],
	}
  },
  components: {
    UniversEventDrawer,
		UniversEventSlider,
    UniversEventAnnonce,
  },
  directives: {
    LazyLoading, 
  },
  computed:{
    sliderIsOpen: function() {
        return this.tabIsVisible.map(function(item) {
            return item;
        });
    }
  },
  methods: {

    whatColor: function(totalLength){
      if (totalLength%2 == 0){
        return 'var(--third-color)'
      }else{
        return 'var(--third-color-lighten)'
      }
    },

    sendToggle: function(index){
      // console.log('sendToggle', index)
      this.toggleSlider(index)
    },
    
		// toggleSlider: function(index){
  //     this.content.archive[index].sliderIsOpen = !this.content.archive[index].sliderIsOpen
		// }

    initTabIsVisible: function(){
      // this.content.archive.forEach((element, index) => this.$set(this.tabIsVisible, index, false))

      if (this.content.annonceDisplay == 'true'){
        this.content.archive.forEach((element, index) => this.$set(this.tabIsVisible, index, false))

      }else {
        this.content.archive.forEach((element, index) => {
          if (index == 0 ){
            this.$set(this.tabIsVisible, index, true)
          }else {
            this.$set(this.tabIsVisible, index, false)
          }
        })

      }
    },
    toggleSlider: function(index){

      if ( this.tabIsVisible[index] == true ){

        let elementTitle = this.$el.querySelectorAll('.UniversEventDrawer')[index].querySelector('.UniversEventDrawer--container')
        let elementBottomPosition = elementTitle.getBoundingClientRect().bottom
        let RemoveToScrollTop = window.innerHeight - elementBottomPosition

        this.$el.parentNode.scrollTo({
          top: this.$el.parentNode.scrollTop - RemoveToScrollTop,
          left: 0,
          behavior: 'smooth'
        });
      }

      this.$set(this.tabIsVisible, index, !this.tabIsVisible[index])
    },

    onResize: function(){      
      if (this.whatComputConfig != '-mobile'){
        this.tabIsVisible.forEach((element, index) => {
          this.$set(this.tabIsVisible, index, false)
        })
      }
    }
  },

  created(){
    this.initTabIsVisible()
  },

  mounted(){
    window.addEventListener('resize', this.onResize)
  },

  beforeDestroy(){
    window.removeEventListener('resize', this.onResize)
  }
}

</script>

<style lang="scss">
@import '@/css/variables';
@import '@/css/transition';

.UniversEvent{
  position: relative;
	display: flex;
	flex-wrap: wrap;
  overflow: hidden;

  &--outro{
      font-size: var(--univers-font-labeur-rgl);
      text-align: center;
      padding: var(--esp-big);

      & a {
        text-decoration: underline;
      }

  }

  &--annonce-container{
    background-color: var(--third-color-lighten);
    width: 100%;
  }

	&--archive-container{
    width: 100%;
	}
}

</style>
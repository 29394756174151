<template>
  <!-- <div class="UniversWorkshopContent" v-html="content"> -->
  <div class="UniversWorkshopContent" v-htmltest>  

  </div>
</template>

<script>

export default {
  name: 'UniversWorkshopContent',
  props: ['content', 'contentFigure', 'index', 'context', 'thumbWidth'],
  directives:{
    htmltest: {

      bind: function(el, binding, vnode){
        el, binding, vnode
        // el.innerHTML = vnode.context.content.text

        // let DOM_figures = Array.prototype.slice.call(el.querySelectorAll('figure'))
        // let images_collection = vnode.context.content.media_collection

        // let varImgWidth = Number(window.getComputedStyle(document.documentElement).getPropertyValue('--univers-thumb-workshop-width').split('vw')[0])

        // images_collection.forEach((element, index) => {

        //   let img = new Image();

        //   img.onload = function() {
        //     let natureWidth = img.width
        //     let natureHeight = img.height

        //     /*en pixel*/
        //     // let imgWidth = varImgWidth * window.innerWidth / 100
        //     // let imgHeight = imgWidth * natureHeight / natureWidth
        //     // img.style.width = imgWidth + 'px'
        //     // img.style.height = imgHeight + 'px'

        //     /*en vw*/
        //     let imgWidth = varImgWidth
        //     let imgHeight = imgWidth * natureHeight / natureWidth
        //     img.style.width = imgWidth + 'vw'
        //     img.style.height = imgHeight + 'vw'

        //     img.srcset = element.srcset
        //     DOM_figures[index].appendChild(img)
        //   }
        //   img.src = element.thumb

        //   DOM_figures[index].appendChild(img)
        // })

        // console.log(vnode.context.context)

        el.innerHTML = vnode.context.content

        let DOM_figure = el.querySelector('figure')
        let image = vnode.context.contentFigure

        // console.log(DOM_figure, image)

        let varImgWidth = Number(window.getComputedStyle(document.documentElement).getPropertyValue('--univers-thumb-workshop-width').split('vw')[0])

        let img = new Image();
        img.onload = function(){

          let natureWidth = img.width
          let natureHeight = img.height   
          
          /*en vw*/
          let imgWidth = varImgWidth
          let imgHeight = imgWidth * natureHeight / natureWidth
          img.style.width = imgWidth + 'vw'
          img.style.height = imgHeight + 'vw'   

          if ( vnode.context.context == '-mobile'){ 
            // img.classList.add('workshopContent-mobile')
            img.style.opacity = 1
          }
          
          if(!(img.dataset.state == 'inserted')){
            DOM_figure.appendChild(img)
            img.dataset.state = 'inserted'
          }
          // DOM_figure.appendChild(img)
        }
        img.src = image
        // console.log(image)
      }
    }
  },
  data: function () {
    return {
      sliderIsOpen: false,
      thumbIsActive: false
    }
  },
  computed:{



  },
  methods: {


  },
  created(){

    // console.log(this.content)
    // console.log(this.contentFigure)

  }
  
}
</script>

<style lang="scss">
@import '@/css/variables';

.UniversWorkshopContent{

  font-size: var(--univers-font-labeur-rgl);
  text-align: center;

  & p {
    padding: var(--esp-big);
  }

  & figure{
    display: flex;
    justify-content: center;
    margin-bottom: var(--esp-big);  
  }

  & img{
    // width: var(--univers-thumb-workshop-width);
    opacity: 0;
    height: 100%;
  }  

}



</style>